.promo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3vh;
}

.promoText {
    margin: 0;
    font-size: 15px;
    text-align: center;
    letter-spacing: 3px;
}

.promoText a {
    color: black;
}

.promoText a:hover {
    color: white;
    background-color: rgb(78, 176, 155);
    padding: 3px;
}

@media screen and (max-width: 48em) {
    .promoText {
        margin-top: 2px;
        font-size: 12px;
        text-align: center;
        letter-spacing: 2px;
    }
}