@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

* {
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 1px;
}

body, html {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Archivo', sans-serif ;
  }

.general {
    background-color: #f5f8f8;
    height: 100%;
}