@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Sunflower:wght@300&family=Zeyada&display=swap');

.landing {
    margin-top: 33.3vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.landingSection {
    width: 100%;
    font-family: 'Sunflower', sans-serif;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.landingQuote {
    background-image: url(../../assets/thumbnail_IMG_0409.jpg);
    background-color: white;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: left;
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.landingQuote h4 {
    background-color: rgba(188, 244, 241, 0.901);
    padding: 15px;
    font-weight: 300;
    font-size: 3vh;
    opacity: 1;
}

.welcomeText {
    line-height: 30px;
    font-size: 20px;
}

.welcomeText p {
    width: 50%;
    padding: 10px;
    margin-left: 3.5vw;
}

.booking {
    background:
        linear-gradient(rgba(126, 126, 126, 0.705),
        rgba(83, 82, 82, 0.758)),
        url('../../assets/adrien-king-XzBluXxBoyQ-unsplash.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.booking h1 {
    width: 100%;
    text-align: center;
    font-size: 70px;
    color: white;
    margin: 7vh 0 2vh 0;
}

.booking p {
    color: white;
}

.helpContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.help {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80vw;
    color: white;
    margin-bottom: 10vh;
}

.helpIntroduction {
    width: 80vw;
    text-align: center;
}

.help p {
    text-align: center;
    margin: 10px;
}

.help h3 {
    width: 100%;
    text-align: center;
    font-size: 30px;
}

.helpContainer {
    margin: 5vh 0 0 0;
}

.helpContainer p {
    font-size: 19px;
}

.learnMore div {
    display: flex;
    width: 40%;
    margin: 2%;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.bookNow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 100%;
}

.bookNow a {
    color: rgb(21, 97, 97);
    text-decoration: none;
    padding: 10px 13px 17px 13px;
    border: 2px rgb(164, 161, 161) solid;
    height: 20px;
    font-size: 22px;
}

.bookNow p {
    font-size: 25px;
    text-align: center;
    width: 92vw;
    margin-bottom: 7vh;
}

.bookNowContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.homeLinks {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.homeLinks a {
    margin-bottom: 1vh;
}

@media screen and (max-width: 48em) {

    .learnMore {
        height: 100%;
    }

    .learnMore div {
        width: 80vw;
    }

    .learnMoreContainer {
        padding: 5vh 0 10vh 0;
    }

    .welcomeText {
        height: 100%;
    }

    .welcomeText p {
        margin: 2vh 0 10vh 0;
        width: 90vw;
    }

    .booking {
        height: 100%;
    }

    .landingSection h1 {
        font-size: 40px;
    }

    .landingQuote {
        background-image: url(../../assets/thumbnail_IMG_0409.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left;
        height: 40vh;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .bookNow a {
        font-size: 18px;
    }
    
    .bookNow p {
        font-size: 20px;
    }
}

@media screen and (min-width: 800px) {

    .helpContainer {
        justify-content: space-around;
    }

    .help {
        width: 20vw;
    }
}