@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Sunflower:wght@300&family=Zeyada&display=swap');

.banner {
    position: absolute;
    top: 3vh;
    height: 45vh;
    width: 100%;
    display: flex;
    align-items: end;
    background-image: url('../../assets/thumbnail_IMG_0422.jpg');
    background-size: cover;
    background-position: left;
    color: rgb(21, 97, 97);
    border-top: 1px black solid;
    border-bottom: 1px rgb(0, 0, 0) solid;
}

.bannerTitle {
    width: 100%;
    margin-right: 6vw;
}

.bannerTitle p{
    width: 100%;
    text-align: end;
    font-size: 30px;
}

@media screen and (max-width: 48em) {
    .bannerTitle p{
        width: 100%;
        padding-top: 2px;
        font-size: 18px;
        font-family: 'Sunflower', sans-serif;
        background-color: rgba(255, 255, 255, 0.788);
    }
}

