.about-section {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    background-size: cover;
    background-position: center;
    margin-top: 33.3vh;
}

.me {
    border-radius: 50%;
    height: 40vh;
    margin-top: 8vh;
    border: 5px solid rgb(21, 97, 97, 0.5);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    margin-bottom: 5vh;
}

.aboutIntro {
    text-align: center;
    width: 80%;
    border-top: 5px solid rgb(21, 97, 97, 0.5);
    border-bottom: 5px solid rgb(21, 97, 97, 0.5);
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 25px;
}

.aboutTitle {
    margin-top: 10vh;
}

.aboutIntro h2 {
    margin: 0;
    letter-spacing: 0;
}

.aboutText {
    width: 80%;
    margin: 5vh 0 10vh 0;
    line-height: 23px;
    font-size: 18px;
}

.verified {
    width: 200px;
}

.createSesh {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2vh 0 10vh 0;
    padding-bottom: 10vh;
    border-bottom: 5px solid rgb(21, 97, 97, 0.5);
}

.createSesh a {
    color: rgb(21, 97, 97);
    text-decoration: none;
    padding: 10px 13px 17px 13px;
    border: 2px rgb(164, 161, 161) solid;
    height: 20px;
    font-size: 22px;
}