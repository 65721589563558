.services {
    position: relative;
    margin-top: 33.3vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.services div {
    width: 100%;
    padding: 5vw;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 5vw;
}

.services a {
    color: rgb(21, 97, 97);
    text-decoration: none;
    padding: 8px 10px;
    font-size: 18px;
    border: 2px rgba(21, 97, 97, 0.582) solid;
}

.services p {
    font-size: 20px;
}

.introductory {
    background-color: rgba(255, 255, 255, 0.804);
}

.teenSession {
    text-align: right;
    color: rgba(255, 255, 255, 0.916);
    background-color: rgba(21, 97, 97, 0.676);
}

.session {
    background-color: rgba(255, 255, 255, 0.804);
}

.teenSession p {
    text-align: left;
}

.teenSession a {
    color: white;
    border: 2px rgba(184, 214, 214, 0.947) solid;
}

.btnDiv {
    width: 100%;
    display: flex;
    justify-content: center;
}

.billing {
    width: 90vw;
    margin-top: 10vh;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .services div {
        width: 80vw;
        margin: 5vh;
    }
}