@media screen and (max-width: 48em) {
    iframe {
        width: 90vw;
    }
}

.contactStatement p {
    width: 40vw;
    margin-top: 6vh;
    font-size: 18px;
}

.location {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    margin-top: 33.3vh;
    align-items: center;
}

iframe {
 margin: 5vw 0 5vw 0;
}

.address p {
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
}

.address p a {
    text-decoration: none;
}

@media screen and (width < 700px) {
    .address p {
        font-size: 16px;
    }

    .address {
        margin-top: 5vh;
    }

    iframe {
        margin-bottom: 8vh;
    }

    .contactStatement p {
        width: 90vw;
    }
}