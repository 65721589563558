.bannerMenu {
    position: sticky;
    top: 0;
    width: 100%;
    height: 6vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: rgba(188, 244, 241, 0.901);
    padding: 5px 0 9px 0;
    margin-top: 4vh;
    z-index: 9999;
    align-items: center;
}

.logoTitle {
    letter-spacing: 1px;
    margin: 0;
    font-size: 40px;
    color: rgb(21, 97, 97);
    margin-left: 1vw;
}

.logoWords {
    margin: 17px 0 0 5px;
    letter-spacing: 1px;
    font-family: 'Cedarville Cursive';
    font-size: 18px;
    color: rgb(21, 97, 97);
}

.logo {
    display: flex;
}

.bannerMenu a {
    color: rgb(21, 97, 97);
    text-decoration: none;
    padding: 8px 10px;
    font-size: 18px;
}

.bookingBtn {
    border: 2px rgba(21, 97, 97, 0.582) solid;
    align-self: center;
}

.bookingBtn:hover {
    color: white;
}

a:hover {
    background-color: rgba(153, 153, 153, 0.689);
    color: rgb(255, 255, 255);
    transition: 0.1s ease;
}

.learnMore a:hover {
    color: white;
}

.nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none; 
    outline: none; 
    display: none;
    opacity: 0;
}

.navItems {
    width: 60vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media screen and (max-width: 925px) {

    .nav-btn {
        display: block;
        opacity: 1;
        font-size: 30px;
        color: rgb(21, 97, 97);
    }
    
    .logoTitle {
        letter-spacing: 1px;
        margin-top: 3px;
        font-size: 30px;
        color: rgb(21, 97, 97);
        margin-left: 1vw;
    }
    
    .logoWords {
        margin: 13px 0 0 5px;
        letter-spacing: 1px;
        font-family: 'Cedarville Cursive';
        font-size: 14px;
        color: rgb(21, 97, 97);
    }

    nav {        
        position: fixed;
        margin-top: 2.5vh;
        top: 10px;
        right: 10px;
        height: 50vh;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: rgba(188, 244, 241, 0.901);
        transition: 0.5s ease-in-out;
        transform: translateY(-100vh);
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .responsive_nav {
        transform: none;
    }

    nav a {
        width: 100%;
    }

    .nav-btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .nav-btn-open {
        margin-right: 2vw;
    }

    .bookingBtn {
        width: 200px;
    }
}

@media screen and (max-height: 750px) {
    .bannerMenu a {
        padding: 2px 10px;
    }   
}